@tailwind base;
@tailwind components;
@tailwind utilities;

.labelForm {
  font-size: 14px;

  color: #000000;

  line-height: 22px;
}

.cms_subheading {
  text-transform: capitalize;
  font-size: 16px;
  color: rgba(3, 166, 90, 0.7);
  font-weight: 500;
}

.btn_delete {
  font-size: 12px;
  text-transform: capitalize;
  color: rgba(255, 0, 0, 0.5) !important;
  margin: 0 20px;
}

.btn_delete:hover {
  color: rgba(255, 0, 0, 0.7) !important;
}

.btn_addMore{
  color: mediumblue !important;
}

.btn_addMore:hover{
  text-decoration: underline !important;
}